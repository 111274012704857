import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import WaitingList from '../../img/illustrations/waiting-list.svg'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    const name = e.target.name
    if (name.includes('[]')) {
      this.setState(state => {
        let array = state[name] || []
        array = e.target.checked === true ? [...array, e.target.value] : array.filter(element => element !== e.target.value)
        return {
         ...state,
         [name]: array
      }})
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {

    return (
      <Layout>
        <section className="section wait-listing">
          <div className="container">
            <div className="columns is-centered is-vcentered">
              <div className="column is-6">
                <img src={WaitingList} className="image" />
              </div>
              <div className="column is-6">
                <div className="content">
                  <h1>Uy.. Willing to wait siya.</h1>
                  <p>Don't worry, we will do our best to on-board you very soon. Salamat!</p>
                  <form
                    name="waitlist"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="waitlist" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'name'}>
                        Name:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'role'}>
                        I'm currently:
                      </label>
                      <div className="control">
                        <div class="select is-primary" style={{ width: '100%' }}>
                            <select 
                              className="input"
                              type={'text'}
                              name={'role[]'}
                              onChange={this.handleChange}
                              id={'role'}
                              required={true}
                            >
                              <option value="Studying">Studying</option>
                              <option value="Employed">Employed</option>
                              <option value="Unemployed">Unemployed</option>
                              <option value="Business Owner">Owned a Business</option>
                              <option value="Others">Others</option> 
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field subtype">
                      <label className="label" htmlFor={'subtype'} style={{ marginBottom: '16px'}}>
                        What digital subscriptions do you currently pay?
                      </label>

                      <label class="checkbox">
                        <input type="checkbox" name="subtype[]" id="sub-type1" value="Streaming" onChange={this.handleChange} />
                          Streaming services (Netflix, Hulu, Disney+, etc.)
                      </label>

                      <label class="checkbox">
                        <input type="checkbox" name="subtype[]" id="sub-type2" value="News" onChange={this.handleChange} />
                          Online news outlets (The New York Times, The Washington Post, etc.)
                      </label>

                      <label class="checkbox">
                        <input type="checkbox" name="subtype[]" id="sub-type3" value="Music" onChange={this.handleChange} />
                          Music streaming services (Spotify, Apple Music, etc.)
                      </label>
                      
                      <label class="checkbox">
                        <input type="checkbox" name="subtype[]" id="sub-type3" value="Learning" onChange={this.handleChange} />
                        Online learning platforms (Udemy, Coursera, etc.)
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'mobile'}>
                        Mobile Number:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'tel'}
                          name={'mobile'}
                          onChange={this.handleChange}
                          id={'mobile'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'email'}>
                        Email:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'message'}>
                        Is there anything you want to add?
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="button is-black is-rounded is-flex is-full-width" type="submit" style={{ width: '100%', fontSize: '1.25rem' }}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
